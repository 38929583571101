.carousel .slider-wrapper {
    display: flex;
    align-items: center;
}

.carousel .slider {
    display: flex;
}

.carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
}